<template>
  <div class="flex flex-col">
    <p class="flex flex-row items-center text-lg font-semibold">
      Are you sure, you want to deactivate this business?
    </p>
    <p class="text-sm md:text-base pt-3 pb-5">
      By Deactivating, you will never be able to get this business back active
      again. This will cancel any subscription or purchases made for this
      specific business.
    </p>
    <div class="flex flex-row w-full">
      <BaseButton
        text-size-class="text-sm md:text-base"
        width-class="w-1/2"
        primary-color-class="a-error"
        text-color-class="white"
        margin-classes="my-2 mx-1"
        @click.native="deactivateBusiness()"
        :loading="deactivateBusinessLoading"
      >
        Yes, Deactivate
      </BaseButton>
      <BaseButton
        text-size-class="text-sm md:text-base"
        width-class="w-1/2"
        isTextOnly
        text-color-class="a-neutral-lightest"
        margin-classes="my-2 mx-1"
        @click.native="$emit('closeModal')"
      >
        No
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DashboardBusiness } from '~/types';


const deactivateBusinessLoading = ref(false);

const props = defineProps<{
  business: DashboardBusiness | null;
}>();

const emit = defineEmits(['closeModal']);

async function deactivateBusiness() {
  deactivateBusinessLoading.value = true;
  await useBusinessesDataManager().deactivateCurrentDashboardBusiness();
  emit('closeModal');
  deactivateBusinessLoading.value = false
}  
</script>

<style></style>
